import { getApi } from "@utils/baseApi"
import { filterNullValues } from "@utils/paywallUtilities"

const getCourseSpeakerData = async (url: string, ...args: any) => {
  const serverData = args?.[3] // accepting serverData only
  const rawData = await getApi(url.replace(/\?.*/, ""), false)
  const getSlideInfo = async (slide: any): Promise<any | null> => {
    return {
      testimonialContent: {
        name: slide?.field_leader_name ?? "",
        designation: slide?.field_leader_designation ?? "",
        summary: { value: slide?.field_leader_information },
      },
      image: slide?.field_leader_image_?.[0]?.src ?? null,
      alt: slide?.field_leader_image_?.[0]?.alt ?? null,
      logoImageUrl: null,
      logoImageAlt: null,
      styledimage: slide?.field_leader_image_?.[0]?.image_style_uri ?? null,
    }
  }
  const slidesInfo = await Promise.all(rawData.map(async (slide: any) => getSlideInfo(slide)))

  return {
    slidesInfo: filterNullValues(slidesInfo) ?? [],
    title: rawData?.[0]?.sa_key_opinion_leader_cta_label ?? "",
  }
}
export default getCourseSpeakerData
