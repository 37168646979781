import { ITableWithScrollProps } from "./_tableWithScroll.interface"

const TableWithScroll = ({
  coltitles = [],
  rowsvalues = [],
  className = "",
  tableHeaderClass = "",
  tableColumnClass = "",
}: ITableWithScrollProps) => {

  if (!coltitles.length && !rowsvalues.length) {
    return <></>
  }
  return (
    <div className="table-with-scroll scrollable-content">
      <table className={`table no-border ${className}`}>
        <tbody>
          <tr>
            {coltitles.map((col: any, i: any) => (
             col?.value != null && <th key={i} className={`${tableHeaderClass}`}>
                {col?.value}
              </th>
            ))}
          </tr>
          {rowsvalues.map((row: any, i: any) => (
            <tr key={i}>
              {row.map((val: any, key: number) => (( coltitles[key]?.value !=null) &&
                <td className={`${tableColumnClass} ${val?.cellStyle}`} key={i.toString() + key}>
                  {val?.value}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default TableWithScroll