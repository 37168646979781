import { getApplicationPathFromURL } from "@utils/session-util/sessionHandler"
import { useSelector, useDispatch } from "react-redux"
import { Dispatch, useCallback, useContext, useEffect } from "react"

import { resetUser } from "store/actions/ActionCreators"
import { setUserStateGlobally } from "store/actions/CommonActionCreators"
import ApplicationContext from "@utils/application-context/applicationContext"
import { clearSession } from "store/actions/SessionCreators"
import { useRouter } from "next/router"
import * as Sentry from "@sentry/nextjs"

const TabChangeDetector = () => {
  const dispatch: Dispatch<any> = useDispatch()
  const applicationId: any = useSelector((state: any) => state.appSession.applicationId)
  const user: any = useSelector((state:UserState) => state.user)

  const router = useRouter()

  const deleteUserFromStore: any = useCallback(
    (value: any) => dispatch(resetUser(value)),
    [dispatch],
  )
  const resetUserGlobally: any = useCallback(
    (isLoggedIn: boolean, type: string) => dispatch(setUserStateGlobally(isLoggedIn, type)),
    [dispatch],
  )

  const clearSessionData: any = useCallback(() => dispatch(clearSession()), [dispatch])

  const { applicationConfiguration } = useContext(ApplicationContext)
  // flag to show Cart Icon for non-loggedIn users
  const showCartIcon = applicationConfiguration?.siteConfig?.webstore_enable_non_loggedin_users === 1

  const handleVisibilityChange = async () => {
    try {
     Sentry.captureMessage("document.visibilityState:"+ document.visibilityState,user?.isLoggedIn)
      if (document.visibilityState === "visible") {
        // check if show in cart icon enabled or not
        if (showCartIcon !== undefined && !showCartIcon || user?.isLoggedIn) {
          // check the cookie is present or not
          const url = `/api/data-provider/?id=check-cookie&appid=${applicationId}`
          const response = await fetch(url, {
            headers: {
              domainPath: getApplicationPathFromURL(),
            },
          })
          const data = await response.json()
          if (data.isExpire) {
            // clear storage as well
            const isLoginStateUpdated = user?.isLoggedIn || false
            deleteUserFromStore({})
            resetUserGlobally(false, "")
            clearSessionData()
            if(isLoginStateUpdated) {
              router.reload()
            }
          }
        }
      }
    } catch (error) {}
  }

  useEffect(() => {
    document.addEventListener("visibilitychange", handleVisibilityChange)
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange)
    }
  }, [])

  return null
}

export default TabChangeDetector
