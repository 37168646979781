/* tslint:disable */
import { AES } from "crypto-js"
import memoryCache, { CacheClass } from "memory-cache"
import { getApiDomainAndLang, middlewarePostAPI } from "@utils/baseApi"
import { config, localeArray } from "@utils/baseApi/config.external"
import isServer from "@utils/fs/isServer"
import { getAssetPrefixUrl } from "@utils/helper"
// import domainData from "../../public/data/domain.json"
import logMessage from "@utils/fs/logger"
import { LOG_TYPES } from "@helpers/constants/logger"
import * as Sentry from "@sentry/nextjs"

const memCache: CacheClass<string, string> = new memoryCache.Cache()

export const parseJwt = (token: string) => {
  return JSON.parse(atob(token.split(".")[1]))
}

/* This code is only for Zombie user */
export const parseCustomJwt = (token: string) => {
  const splitVal = token.split(".")
  if (splitVal.length < 3) {
    logMessage(isServer(), LOG_TYPES.ERROR, undefined, undefined, "[Error] Invalid token")
    return null
  }
  try {
    const lastIndex = splitVal?.length - 1
    const encodedPayload = splitVal[lastIndex]
    const decodedPayload = atob(encodedPayload)
    const parsedPayload = JSON.parse(decodedPayload)
    return parsedPayload?.email
  } catch (error) {
    logMessage(isServer(), LOG_TYPES.ERROR, undefined, undefined, "Error parsing:" + error)
    return null
  }
}

export async function getApplicationSession(baseDomain: string) {
  const applicationSessionUrl = `https://${baseDomain}/oauth/token`

  const cachedResponse = memCache.get(baseDomain)
  if (cachedResponse) {
    const tokenExpiryTime = memCache.get(`${baseDomain}-token-expiry`)
    return { access_token: cachedResponse as string,
      expires_in: tokenExpiryTime,
     }
  }

  let variables: any = {
    grantType: process.env.GRANT_TYPE,
    clientId: process.env.CLIENT_ID,
    secrect: process.env.CLIENT_SECRECT,
  }
  try {
    if (isServer()) {
      variables = {
        grantType: process.env.GRANT_TYPE,
        clientId: process.env.CLIENT_ID,
        secrect: process.env.CLIENT_SECRECT,
      }
    } else {
      const { location } = window
      const isLocalhost = !!location.hostname.includes("localhost")
      const url = getAssetPrefixUrl("/api/data-provider/", isLocalhost)
      const response = await fetch(url)
      variables = await response.json()
    }

    const formData = new FormData()
    formData.append("grant_type", variables?.grantType)
    formData.append("client_id", variables?.clientId)
    formData.append("client_secret", variables?.secrect)

    const startDate = Date.now()
    return fetch(applicationSessionUrl, {
      method: "POST",
      body: formData,
    })
      .then((resp: any) => {
        return resp.json()
      })
      .then((response: any) => {
        logMessage(isServer(), LOG_TYPES.LOG, response?.status, applicationSessionUrl, undefined, undefined, (Date.now() - startDate))
        const sessionExpiryTime = process.env.SESSION_EXPIRY
        ? Number(process.env.SESSION_EXPIRY)
        : 240000
        memCache.put(baseDomain, response?.access_token, response?.expires_in || sessionExpiryTime)
        memCache.put(`${baseDomain}-token-expiry`,response?.expires_in, response?.expires_in || sessionExpiryTime)
        const tokenData = {
          access_token: response?.access_token as string,
          expires_in: response?.expires_in,
        }
        return tokenData
      })
  } catch (error) {
    throw new Error("TokenFetchException", {
      cause: `Token has not been generated for ${baseDomain} domain! - ${error}`,
    })
  }
}

export const getDomainDataFromMiddleware = (ctx: any) => {
  let domainData = null
  let domainLang = null
  let feDomainData = null
  let token = null
  let tokenExpiry = null
  let isShield = false
  let sessionCookie = null
  let isMaintenance = null
  let errorMessage = null
  let externalCssPath = null
  let loggingLevel = "0"
  let rewriteAliases = ""

  try {
    const applicationId = ctx?.res?.getHeaders()["domain-application-id"] as string
    sessionCookie = ctx?.req?.cookies[applicationId] ?? null
    Sentry.captureMessage("DOCCHECK ctx request:"+ ctx?.req, ctx?.req?.cookies[applicationId])
    domainData = ctx?.res?.getHeaders()["domain-middleware"]
    feDomainData = ctx?.res?.getHeaders()["domain-fe-middleware"]
    domainLang = ctx?.res?.getHeaders()["domain-lang-middleware"]
    token = ctx?.res?.getHeaders()["access-token"]
    tokenExpiry = ctx?.res?.getHeaders()["token-expiry"]
    isShield = ctx?.res?.getHeaders()["domain-shield-middleware"] === "true"
    errorMessage = ctx?.res?.getHeaders()["domain-error"]
    externalCssPath = ctx?.res?.getHeaders()["domain-external-css"]
    loggingLevel = ctx?.res?.getHeaders()["domain-logging-level"] || "0"
    rewriteAliases = ctx?.res?.getHeaders()["product-rewrite-aliases"] || ""
  } catch (error) {
    console.log(error)
  }

  if (domainData && token) {
    global.domainData = "" // domainData
    global.feDomainData = "" // feDomainData
    global.domainLang = "" // domainLang
    global.token = "" // token
    global.isShield = isShield
    global.session = "" // sessionCookie
    global.externalCssPath = "" // externalCssPath
    isMaintenance = "0"
  } else {
    isMaintenance = "1"
    global.domainData = ""
    global.feDomainData = ""
    global.domainLang = ""
    global.token = ""
    global.isShield = false
    global.session = ""
  }

  return {
    domainData,
    feDomainData,
    domainLang,
    token,
    tokenExpiry,
    isShield,
    sessionCookie,
    isMaintenance,
    errorMessage: errorMessage === undefined ? null : errorMessage,
    externalCssPath,
    loggingLevel,
    rewriteAliases,
  }
}

export const fetchDomainMappingData = async (
  currentDomain: string,
  token: string,
  isClearCache?: boolean,
  nextURL?: string,
  domainDataSource?: Array<any>,
): Promise<any> => {
  /**
   * For local setup
   * uncomment this code
   * update the necessary doamin in "../../public/data/domain.json" file
   */
  // if (domainData) {
  //   const selectedDomain = getMappedContent(domainData.data, currentDomain)
  //   return selectedDomain
  // }

  const domainMappingHost = process.env.SCC_DOMAIN
  let domainMappingUrl = `https://${domainMappingHost}/jsonapi/taxonomy_term/domain_mapping?page[limit]=500`
  if (nextURL) {
    domainMappingUrl = nextURL
  }

  const sessionExpiryTime = process.env.SESSION_EXPIRY ? Number(process.env.SESSION_EXPIRY) : 240000
  const cachedResponse = memCache.get("DOMAIN_DATA")
  if (isClearCache) {
    memCache.clear()
  }
  if (!currentDomain) {
    return [{}]
  }

  if (cachedResponse && !nextURL) {
    const data = JSON.parse(cachedResponse)
    return getMappedContent(data, currentDomain)
  }

  try {
    const domainStartDate = Date.now()
    const domains = await fetch(domainMappingUrl, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    const response = await domains.json()

    if (response && response?.data) {
      logMessage(isServer(), LOG_TYPES.LOG, response?.status, domainMappingUrl, undefined, undefined, (Date.now() - domainStartDate))
      let stepDomainsDataSource: Array<any> = response?.data as Array<any>
      const nextUrl = response?.links?.next?.href

      if (domainDataSource) {
        stepDomainsDataSource = stepDomainsDataSource.concat(domainDataSource)
      }

      if (nextUrl) {
        return fetchDomainMappingData(currentDomain, token, false, nextUrl, stepDomainsDataSource)
      }
      memCache.put("DOMAIN_DATA", JSON.stringify(stepDomainsDataSource), sessionExpiryTime)
      return getMappedContent(stepDomainsDataSource, currentDomain)
    }
  } catch (error) {
    throw new Error("DomainMappingAPIException", {
      cause: `Domain mapping API has some error! - ${error}`,
    })
  }
}

export const getMappedContent = (domains: Array<any>, frontendDomain: string) => {
  return domains
    .filter((d: any) => {
      return d.attributes.field_fe_domain === frontendDomain
    })
    .map((selectedItem: any) => {
      return {
        apiDomain: selectedItem?.attributes?.field_be_cms_domain,
        siteDoamin: selectedItem?.attributes?.field_fe_domain,
        lang: selectedItem?.attributes?.field_cms_language,
        isShield: selectedItem?.attributes?.field_shield ?? true,
        externalCSSPath: selectedItem?.attributes?.css_file_path ?? "",
      }
    })
}

export const saveError = (error: any) => {
  const timestamp = new Date()
  memCache.put(timestamp.toString(), error)
}

export const getTokenForPreview = async () => {
  const { feDomain } = getApiDomainAndLang()
  const { location } = window
  const isLocalhost = !!location.hostname.includes("localhost")

  // extract CMS path
  const path = feDomain?.includes("/") ? feDomain?.split("/")?.[1] : ""
  const configureUrl = path
    ? `/api/renew-token/?id=preview&ccll=${path}`
    : "/api/renew-token/?id=preview"

  const url = getAssetPrefixUrl(configureUrl, isLocalhost)

  const response = await fetch(url)
  const { token } = await response.json()

  return token
}

export const getApplicationPathFromURL = () => {
  const url = window.location.href
  const paths = url.split("/")
  const filterDataArray = paths.filter((p: string) => {
    return localeArray.some((l: string) => {
      return l === p
    })
  })

  if (filterDataArray && filterDataArray.length) {
    return filterDataArray?.[0]
  }

  return "/"
}

/**
 * Sets up user cookies with given session ID and csrf Token
 * @param sessionId
 * @param csrfToken
 */
export const setupCookies = async (sessionId: string, csrfToken: string, applicationId: string) => {
  const salt = process.env.USER_COOKIE_SALT as string
  const isLocalhost = !!location.hostname.includes("localhost")
  const url = getAssetPrefixUrl(`/api/data-provider/?id=user&appid=${applicationId}`, isLocalhost)
  const { feDomain } = getApiDomainAndLang()

  const response = await fetch(url, {
    headers: {
      sessionId: AES.encrypt(sessionId, salt).toString(),
      csrfToken: AES.encrypt(csrfToken, salt).toString(),
      domainPath: getApplicationPathFromURL(),
      feDomain: feDomain,
    },
  })
  await response.json()
}

/**
 * This function is created for Sandoz ID
 * It takes tid (unique id for logged in user)
 * sets up user cookies
 * @param body tid to fetch csrf and session ID
 * @param serverData
 * @returns csrf token and session ID for the user session
 */
export const fetchTokenAndSessionID = async (body: any, serverData?: any) => {
  try {
    const sessionData: any = await middlewarePostAPI(
      config.onPrem.GET_SESSION_CSRF_API,
      {
        token: body,
      },
      false,
      serverData,
    )

    if (!sessionData.fail) {
      if (sessionData?.response?.code === 403) {
        throw new Error("Failed to fetch session data")
      }
      // setup user session
      const csrf_token = sessionData?.response?.session_data?.csrf_token
      const hashedSessionId = sessionData?.response?.session_data?.hashedSessionId
      // generate cookies here
      // await setupCookies(hashedSessionId, csrf_token)
      return {
        csrf_token,
        hashedSessionId,
      }
    }
  } catch (error) {
    console.error("Something went wrong", error)
  }
}
