import { LOGIN_TYPES } from "@helpers/constants/generic"
import { getApplicationPathFromURL } from "@utils/session-util/sessionHandler"
import { Dispatch, useCallback, useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { addUser, resetUser, setUserProfile } from "store/actions/ActionCreators"
import { setUserStateGlobally } from "store/actions/CommonActionCreators"
import { clearSession } from "store/actions/SessionCreators"

const useUserSessionControl = () => {
  const [profileLoading, setProfileLoading] = useState<boolean>(false)
  const [renewUserSession, setRenewUserSession] = useState<boolean>(false)
  const [renewSandozIdSession, setSandozIdSession] = useState<boolean>(false)
  const userData: any = useSelector((state: UserState) => state?.user)
  const appData: any = useSelector((state: any) => state?.common)
  const { applicationId, session, csrf } = useSelector((state: any) => state.appSession)

  const dispatch: Dispatch<any> = useDispatch()
  const deleteUserFromStore: any = useCallback(
    (value: any) => dispatch(resetUser(value)),
    [dispatch],
  )
  const setUserStatusGlobally: any = useCallback(
    (isLoggedIn: boolean, type: string) => dispatch(setUserStateGlobally(isLoggedIn, type)),
    [dispatch],
  )
  const updateUserLoginData: any = useCallback((value: any) => dispatch(addUser(value)), [dispatch])
  const updateUserProfile: any = useCallback(
    (value: any) => dispatch(setUserProfile(value)),
    [dispatch],
  )
  const clearSessionData: any = useCallback(() => dispatch(clearSession()), [dispatch])


  const isActiveSession = useMemo(() => {
    // check for same tab
    if (userData.isLoggedIn && appData.isLoggedIn) {
      return true
    }

    // check different tab
    if (userData.isLoggedIn === false && appData.isLoggedIn === true) {
      setRenewUserSession(true)
    }

    // for sandozId
    /**
     * Check the session & csrf token present on redux or not
     * same domain for different language the cookie should be present
     * if present - call manageUserSession
     *
     * userData - sessionstorage - should be false
     * appData - localstorage - should be false
     */
    if (session && csrf && userData.isLoggedIn === false && appData.isLoggedIn === false) {
      setSandozIdSession(true)
    }

    if (userData.isLoggedIn === false && appData.isLoggedIn === false) {
      setRenewUserSession(false)
      return false
    }
  }, [userData, appData])

  const manageUserSession = useCallback(
    async (isRenew: boolean) => {
      try {
        if (isRenew && appData.userType) {
          setProfileLoading(true)
          const data = await checkUserLoginDetails(appData.userType)

          if (appData.userType === LOGIN_TYPES.SOLAR) {
            // set user profile
            const { profile, user, isExpire } = data

            updateUserLoginData(user)
            updateUserProfile(profile)

            if (isExpire) {
              clearUserSessionData()
            }
          } else if (appData.userType === LOGIN_TYPES.DOC_CHECK) {
            const { isExpire, LoginData, Profile } = data
            if (!isExpire) {
              updateUserLoginData(LoginData)
              updateUserProfile(Profile)
            } else {
              localStorage.removeItem("Key")
              clearUserSessionData()
              deleteUserFromStore({})
              setUserStatusGlobally(false, "")
            }
          }
          setProfileLoading(false)
        }
        // else {
        //   clearUserSessionData()
        // }
      } catch (error) {
        clearUserSessionData()
        setProfileLoading(false)
        deleteUserFromStore({})
        setUserStatusGlobally(false, "")
      }
    },
    [setRenewUserSession, appData],
  )

  const checkUserLoginDetails = useCallback(
    async (type: string) => {
      try {
        if (appData.cmsUrl) {
          // extract CMS path
          const path = appData?.feUrl?.includes("/") ? appData?.feUrl?.split("/")?.[1] : ""
          const configureUrl = path ? `/api/renew-token/?ccll=${path}` : "/api/renew-token/"

          const tkResponse = await fetch(configureUrl)
          const { token } = await tkResponse.json()

          let url = ""
          let options
          if (type === LOGIN_TYPES.DOC_CHECK) {
            const tokenKey = JSON.parse(localStorage.getItem("Key") ?? "")
            url = `/api/user-validation/?type=${type}&key=${tokenKey}&appid=${applicationId}`
            options = {
              headers: {
                token,
                domain: appData.cmsUrl,
              },
            }
          } else {
            url = `/api/user-validation/?type=${LOGIN_TYPES.SOLAR}&appid=${applicationId}`
            options = {
              headers: {
                token,
                domain: appData.cmsUrl,
                domainPath: getApplicationPathFromURL(),
              },
            }
          }
          const response = await fetch(url, options)
          const data = await response.json()
          return data
        }
      } catch (error) {
        throw new Error("UserSessionExpireException")
      }
    },
    [appData],
  )

  const clearUserSessionData = async () => {
    try {
      // clear cookie
      const url = `/api/data-provider/?id=user&appid=${applicationId}`
      const response = await fetch(url, {
        headers: {
          domainPath: getApplicationPathFromURL(),
        },
      })
      const data = await response.json()

      if (data.flush) {
        // clear storage as well
        deleteUserFromStore({})
        setUserStatusGlobally(false, "")
        clearSessionData()
      }
    } catch (error) {}
  }

  /**
   * Renew token for SandozId
   */
  useMemo(() => {
    if (renewSandozIdSession) {
      setUserStatusGlobally(true, LOGIN_TYPES.SOLAR)
      manageUserSession(true)
    }
  }, [renewSandozIdSession])

  return {
    profileLoading,
    setProfileLoading,
    isActiveSession,
    renewUserSession,
    renewSandozIdSession,
    manageUserSession,
    clearUserSessionData,
  }
}

export default useUserSessionControl
