import { Icon, Img, RichText, Seperator } from "@atoms"
import Button from "@atoms/button"
import React, { useEffect, useState } from "react"
import { IBuyNowButton, IBuyNowdata } from "./_buyNowButton.interface"
import { URL_TARGET } from "@helpers/constants/generic"
import useSWRRequest from "@helpers/hooks/useSWRRequest"
import getBuyNowData from "@helpers/dataFunctions/getBuyNowData"
import { middlewareGETAPI } from "@utils/baseApi"
import { useMediaQuery } from "@helpers/hooks"

const BuyNowButton = ({ url }: IBuyNowButton) => {
  const [isexpanded, setIsExpanded] = useState<boolean>(false)
  const [content, setContent] = useState<IBuyNowdata>()
  const isMobile = useMediaQuery("(max-width: 991px)")

  const fetchData = async (url: string) => {
    const response = await middlewareGETAPI(url)
    return response.response
  }
  const { data } = useSWRRequest(url, async () => {
    try {
      return await fetchData(`/jsonapi${url}`)
    } catch (error) {
      console.log(error)
    }
  })

  const handleClick = () => {
    setIsExpanded(!isexpanded)
  }
  const handleNavigation = (value: string) => {
    window.open(value, URL_TARGET.BLANK, "noopener,noreferrer")
    setIsExpanded(!isexpanded)
  }

  useEffect(() => {
    if (!data) {
      return
    }
    getBuyNowData(data).then((res) => setContent(res))
  }, [data])

  const expandedSection = () => (
    <>
      {isexpanded && content && content?.eCommerceSites?.length > 0 && (
        <>
          <div className={`buyNow-list${content?.containerAlignment && !isMobile ?  " buyNow-list__container-" + content?.containerAlignment : ""}`}>
            {content?.eCommerceSites.map(
              (list: any, index: number) =>
                list?.imageUrl && (
                  <li
                    key= {index}
                    role= "menuitem"
                    tabIndex= {index+1}
                    onClick= {() => handleNavigation(list?.siteUrl)}
                    aria-hidden= "true"
                    className= "menuitem">
                    <div className= "menuitem-icon">
                      <Img
                        className="menuitem-icon__logo"
                        alt= {list?.alt}
                        src= {list?.imageUrl}
                        width= {list?.width}
                        height= {list?.height}
                        type= "icon"
                      />
                      <Icon iconName= "cartIcon" className= "buyNow-ContentRight-list" />
                    </div>
                    {list?.siteDesc && <RichText className="menuitem-icon__description" content={list?.siteDesc} />}
                    <Seperator className= "separator" aria-hidden= "true" />
                  </li>
                ),
            )}
          </div>
          <div
            className= "dropdown-background"
            onClick={() => setIsExpanded(false)}
            aria-hidden= "true"
          />
        </>
      )}
    </>
  )
  return !!content && Object.keys(content).length > 0 && !content?.isParentPaywalled ? (
    <div className= "buyNow">
      <Button
        tabindex= {0}
        onClick= {handleClick}
        className= {`buyNow-btn ${isexpanded ? "expanded" : ""}`}>
        <span className= "buyNow-ContentLeft">
          <span className= "buyNow-label">{content?.buttonLabel}</span>
          <Icon iconName= "cartIcon" className="buyNow-ContentRight" />
        </span>
        {isexpanded ? <Icon className="buyNow-btn-chevron" iconName= "chevronUpBuyNow" /> : <Icon  className="buyNow-btn-chevron" iconName= "chevronDownBuyNow" />}
      </Button>
      {expandedSection()}
    </div>
  ) : (
    <></>
  )
}

export default BuyNowButton
