import React, { useCallback, useContext, useEffect, useState } from "react"
import LoginTimeOutModal from "@organisms/loginTimeoutModal/loginTimeoutModal"
import { addEventListeners, removeEventListeners } from "@utils/eventListeners"
import ApplicationContext from "@utils/application-context/applicationContext"
import { Dispatch } from "redux"
import { useDispatch, useSelector } from "react-redux"
import moment from "moment"
import { middlewarePostAPI } from "@utils/baseApi"
import { config } from "@utils/baseApi/config.external"
import { resetUser } from "store/actions/ActionCreators"
import { encodeString } from "@utils/sha256"
import { EVENTS } from "@utils/gtmEvents"
import { dataLayerPush } from "@utils/gtmutils"
import LoginPopup from "@organisms/loginPopup"
import { getAssetPrefixUrl, isDocCheckUser } from "@utils/helper"
import { IPopup } from "@molecules/popup/_popup.interface"
import NUMBERS from "@helpers/constants/numbers"
import { setRefApplicationID, setUserStateGlobally, updateReloadContent } from "store/actions/CommonActionCreators"
import { useRouter } from "next/router"
import useSandozIDAuth from "@helpers/hooks/useSandozIDAuth"
import useUserSessionControl from "@helpers/hooks/userSessionControlHook/useUserSession"

export const LoginTimeout = () => {
  const { applicationHeaderData, applicationConfiguration } = useContext(ApplicationContext)
  const loginTimeoutData = applicationConfiguration?.loginTimeoutData
  const user: any = useSelector((state: UserState) => state?.user)
  const dispatch: Dispatch<any> = useDispatch()

  const [isWarningModalOpen, setWarningModalOpen] = useState(false)
  const [loginPopupShow, setLoginPopupShow] = useState(false)
  const [forgotPasswordShow, setForgotPasswordShow] = useState(false)
  const [showLoginToast, setShowLoginToast] = useState(false)

  const appData: any = useSelector((state: any) => state?.common)


  const { getCurrentAppId } = useSandozIDAuth(applicationConfiguration)

  const { clearUserSessionData } = useUserSessionControl()

  const updateRefAppID: any = useCallback(
    (value: any) => dispatch(setRefApplicationID(value)),
    [dispatch],
  )

  const updateReloadStatus: any = useCallback(
    (value: any) => dispatch(updateReloadContent(value)),
    [dispatch],
  )

  const popup_countdown_time =
    parseInt(loginTimeoutData?.login_timeout_popup_countdown_time) * NUMBERS.SIXTY_THOUSAND
  const idle_logout_time =
    parseInt(loginTimeoutData?.idle_logout_time) * NUMBERS.SIXTY_THOUSAND - popup_countdown_time
  const pageTitle = typeof window === "object" ? document?.title : ""

  const router = useRouter()

  const handleContinue = () => {
    setWarningModalOpen(false)
    document.title = pageTitle
  }

  const handleLogout = () => {
    setWarningModalOpen(false)
    makeUserLogout() // no logout
    setLoginPopupShow(true)
  }

  useEffect(() => {
    if (!user?.isLoggedIn) {
      setWarningModalOpen(false)
    }
  }, [user?.isLoggedIn])

  useEffect(() => {
    if (loginTimeoutData && user?.isLoggedIn && loginTimeoutData?.login_timeout_enable) {
      const idle_logout_timer = () =>
        setTimeout(() => {
          const lastInteractionTime = localStorage.getItem("lastInteractionTime")
          const diff = moment
            .duration(moment().diff(moment(lastInteractionTime).seconds()))
            .asSeconds()
          const timeOutInterval = idle_logout_time >= 0 ? idle_logout_time : NUMBERS.SIXTY_THOUSAND
          if (!user.isLoggedIn) {
            clearTimeout(timeout)
          } else if (diff < timeOutInterval) {
            idle_logout_timer()
          } else if (!isWarningModalOpen) {
            // if user logged in then modal open

            setWarningModalOpen(true)
          }
        }, idle_logout_time || NUMBERS.SIXTY_THOUSAND)

      const popup_countdown_timer = () =>
        setTimeout(() => {
          handleLogout()
        }, popup_countdown_time || NUMBERS.SIXTY_THOUSAND)

      const listener = () => {
        if (!isWarningModalOpen) {
          clearTimeout(timeout)
          timeout = idle_logout_timer()
          localStorage.setItem("lastInteractionTime", moment().toString())
        }
      }

      // Initialization of timers
      let timeout = isWarningModalOpen ? popup_countdown_timer() : idle_logout_timer()
      addEventListeners(listener)
      return () => {
        removeEventListeners(listener)
        clearTimeout(timeout)
      }
    }
  }, [isWarningModalOpen, user?.isLoggedIn, loginTimeoutData?.login_timeout_enable])

  const deleteUserFromStore: any = useCallback(
    (value: any) => dispatch(resetUser(value)),
    [dispatch],
  )

  const resetUserGlobally: any = useCallback(
    (isLoggedIn: boolean, type: string) => dispatch(setUserStateGlobally(isLoggedIn, type)),
    [dispatch],
  )

  const makeUserLogout = async () => {
    localStorage.removeItem("Key")
    if (isDocCheckUser(user)) {
      deleteUserFromStore({})
      clearUserSessionData()
      // reset user globally
      resetUserGlobally(false, "")
    } else {
      const logoutUrl = applicationConfiguration?.isSandozIDEnabled
        ? config.onPrem.LOGOUT_API_URL_SANDOZ_ID
        : config.onPrem.LOGOUT_API_URL
      const data = applicationConfiguration?.isSandozIDEnabled ? { app_id: getCurrentAppId() } : {}
      const response = await middlewarePostAPI(logoutUrl, data)
      deleteUserFromStore(response)
      clearUserSessionData()
      if (!response.fail && applicationConfiguration?.isSandozIDEnabled) {
        let redirectUrl = new URL(window.location.href)
        if (applicationConfiguration?.isGlobalSite) {
          // clearing app_id from store on logout for global site
          updateRefAppID("")
          sessionStorage.setItem("redirection", JSON.stringify(0))
          redirectUrl = appData?.feUrl ? new URL(`${window.location.protocol}//${appData.feUrl}`) : redirectUrl
        }
        redirectUrl.searchParams.delete("tid")
        redirectUrl.searchParams.delete("status_code")
        const azureLogoutUrl = `${applicationConfiguration?.sandozLogoutUrl}?post_logout_redirect_uri=${redirectUrl}`
        window.location.href = azureLogoutUrl
      }
    }

    const userdata = user?.user?.response?.session_data?.user_data?.current_user?.name
    updateReloadStatus(false)
    const trackLogOutEvent = {
      user_id: encodeString(userdata),
    }

    // reset user globally
    resetUserGlobally(false, "")

    // GTM
    dataLayerPush(EVENTS.LOGOUT, trackLogOutEvent)
  }

  const loginPopupProps = applicationHeaderData?.headerData?.loginFormData
  const PopupProps: IPopup = {
    showPopup: loginPopupShow,
    showOverlay: loginPopupShow,
    children: "",
    spinnerIcon: getAssetPrefixUrl("/assets/icons/spinner.svg"),
    closeIcon: {
      url: getAssetPrefixUrl("/assets/icons/close.svg"),
      altText: "close icon",
    },
    className: "loginTimeoutLoginPopup",
    handlePopupShow: setLoginPopupShow,
  }

  return (
    <div>
      {isWarningModalOpen && (
        <LoginTimeOutModal
          showModal={isWarningModalOpen}
          onClose={() => setWarningModalOpen(false)}
          handleContinueSession={handleContinue}
          loginPopupData={loginTimeoutData}
          handleLogout={handleLogout}
          pageTitle={pageTitle}
        />
      )}
      <LoginPopup
        showPopup={loginPopupShow}
        id="login-popup"
        form={loginPopupProps}
        handlePopupShow={(popupShow) => {
          setLoginPopupShow(popupShow)
          if (popupShow) {
            router.reload()
          } else {
            router.push(window.location.pathname, undefined, { shallow: true }).then(() => router.reload())
          }
        }}
        handleForgotPasswordShow={(popupShow: boolean) => setForgotPasswordShow(popupShow)}
        popup={PopupProps}
        loginConfirmation={() => setShowLoginToast(true)}
        logoutNotification={loginTimeoutData?.login_timeout_popup_logged_out_notification}
      />
    </div>
  )
}

export default LoginTimeout
