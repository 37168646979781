import { getApiDomainAndLang } from "@utils/baseApi"
import isServer from "@utils/fs/isServer"
import getNestedObject from "@utils/nestedObjects"
import { getAllRoles, getPaywalledImgData, isPaywallProtected } from "@utils/paywallUtilities"
import { filterURL, getBlockData, getFileByUrl, getImageMeta, getTaxonomy } from "."

const getDetailBlock = async (url: string, ...args: any) => {
  const serverData = args?.[3] // accepting serverData only
  const detailBlocksResponse = await getBlockData(`${url}`, false, serverData)
  const isParentPaywalled = isPaywallProtected(
    getNestedObject(detailBlocksResponse, "data.attributes.enable_paywall_block_level"),
  )?.isPaywallProtectedFlag
  const fieldPaywallRoles = getAllRoles(detailBlocksResponse)
  const detailBlocks = detailBlocksResponse?.data
  const { lang } = getApiDomainAndLang()
  const language = isServer() ? serverData?.language : lang

  const imagePaywallData = isPaywallProtected(detailBlocks?.relationships?.field_card_image)
  const imageList = detailBlocks?.relationships?.field_card_image?.data ?? []
  const images: any[] = []

  for (const image of imageList) {
    const { imageUrl, alt, imageStyledUrl } = getImageMeta({ data: image })
    images.push({
      src: imageUrl,
      alt,
      styledimage: imageStyledUrl ?? null,
    })
  }

  const taxonomies = await getTaxonomy(
    detailBlocks?.relationships?.field_term?.links?.related?.href,
    false,
    serverData,
  )
  const { fileUrl } = await getFileByUrl(
    detailBlocks?.relationships?.field_upload_file_content?.links?.related?.href,
    serverData,
  )

  const date = detailBlocks?.attributes?.field_date ?? null

  return {
    id: detailBlocks?.attributes?.drupal_internal__id ?? "",
    numberOfSlide: 1,
    carouselData: imagePaywallData.isPaywallProtectedFlag
      ? [getPaywalledImgData(imagePaywallData.errorObj)]
      : images,
    contentData: {
      taxonomies: taxonomies ?? null,
      buttonLabel: detailBlocks?.attributes?.field_file_cta_text?.value
        ? detailBlocks?.attributes?.field_file_cta_text?.value
        : null,
      buttonUrl: fileUrl ? filterURL(fileUrl) : null,
      heading: detailBlocks?.attributes?.field_carousel_title?.processed
        ? detailBlocks?.attributes?.field_carousel_title?.processed
        : null,
      content: detailBlocks?.attributes?.body?.processed
        ? detailBlocks?.attributes?.body?.processed
        : null,
      date: isPaywallProtected(detailBlocks?.attributes?.field_date).isPaywallProtectedFlag
        ? null
        : date,
      bookMarkData: { bookmarkStatus: false, className: "bookmark" },
    },
    fieldPaywallRoles,
    isParentPaywalled,
  }
}

export default getDetailBlock
