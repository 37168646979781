import { getAllRoles, getPaywalledImgData, isPaywallProtected } from "@utils/paywallUtilities"
import { ERelatedContent } from "@organisms/relatedContent/_relatedContent.interface"
import moment from "moment"
import { DATE_FORMATS } from "@helpers/constants/generic"
import getNestedObject from "@utils/nestedObjects"
import { filterURL, isExternalLink } from "."
import { getApiDomainAndLang } from "@utils/baseApi"
import NUMBERS from "@helpers/constants/numbers"

export const getEventCardData = (props: any) => {
  const fieldPaywallRoles = getAllRoles(props)
  const { lang } = getApiDomainAndLang()
  const startDate =
    props?.event_dates && props?.event_dates?.length > 0
      ? props?.event_dates[0]?.value
      : ""
  const endDate =
    props?.event_dates && props?.event_dates?.length > 0
      ? props?.event_dates[0]?.end_value
      : ""

  const imagePaywallData = isPaywallProtected(getNestedObject(props, "image_details"))

  return {
    eventCardVerticalContent: {
      id: parseInt(props.nid, 10),
      text: getNestedObject(props, "description")?.[0]?.value,
      heading: isPaywallProtected(getNestedObject(props, "title")).isPaywallProtectedFlag
        ? null
        : getNestedObject(props, "title"),
      startDate: isPaywallProtected(props.event_dates).isPaywallProtectedFlag ? null : startDate,
      endDate: isPaywallProtected(props.event_dates).isPaywallProtectedFlag ? null : endDate,
      contentText: isPaywallProtected(getNestedObject(props, "location")).isPaywallProtectedFlag
        ? null
        : getNestedObject(props, "location"),
      primaryButtonLabel: getNestedObject(props, "primary_cta")?.[0]?.title,
      primaryButtonUrl: filterURL(getNestedObject(props, "primary_cta")?.[0]?.uri),
      primaryIsExternalUrl: isExternalLink(getNestedObject(props, "primary_cta")[0]?.uri),
      secondaryButtonLabel: getNestedObject(props, "secondary_cta")?.[0]?.title,
      secondaryButtonUrl: filterURL(getNestedObject(props, "secondary_cta")?.[0]?.uri),
      secondaryIsExternalUrl: isExternalLink(getNestedObject(props, "secondary_cta")?.[0]?.uri),
      taxonomyLabel: getNestedObject(props, "tags")?.[0],
      time: isPaywallProtected(getNestedObject(props, "duration")).isPaywallProtectedFlag
        ? null
        : getNestedObject(props, "duration"),
      bookmark: false,
    },
    eventCardVerticalImage: {
      src: imagePaywallData?.isPaywallProtectedFlag
        ? getPaywalledImgData(imagePaywallData?.errorObj)?.imageUrl
        : getNestedObject(props, "image_details")?.[0]?.src,
    },
    eventCardVDesktopColumn: 12,
    type: isPaywallProtected(getNestedObject(props, "type")).isPaywallProtectedFlag
      ? null
      : getNestedObject(props, "type"),
    rank: isPaywallProtected(getNestedObject(props, "rank")).isPaywallProtectedFlag
      ? null
      : getNestedObject(props, "rank"),
    fieldPaywallRoles,
  }
}

export const getProductCardData = (props: any) => {
  const fieldPaywallRoles = getAllRoles(props)
  const { lang } = getApiDomainAndLang()

  const date =
    props.event_dates && props.event_dates.length > 0
      ? moment(props.event_dates?.[0].values).locale(lang).format(DATE_FORMATS.MMMM_DD_YYYY) ?? ""
      : ""
  const imagePaywallData = isPaywallProtected(getNestedObject(props, "image_details"))
  return {
    productCardImage: imagePaywallData?.isPaywallProtectedFlag
      ? getPaywalledImgData(imagePaywallData?.errorObj)?.imageUrl
      : getNestedObject(props, "image_details")?.[0],
    productCardContent: {
      id: parseInt(props.nid, 10),
      content: getNestedObject(props, "description")?.[0]?.value,
      heading: isPaywallProtected(getNestedObject(props, "title")).isPaywallProtectedFlag
        ? null
        : getNestedObject(props, "title"),
      buttonLabel: getNestedObject(props, "secondary_cta")?.[0]?.title,
      taxonomyLabel: getNestedObject(props, "tags"),
      href: filterURL(getNestedObject(props, "secondary_cta")?.[0]?.uri),
      isLinkExternal: isExternalLink(getNestedObject(props, "secondary_cta")?.[0]?.uri),

      taxonomies: {
        taxonomyLabel: getNestedObject(props, "tags")?.[0],
      },
      date: isPaywallProtected(props.event_dates).isPaywallProtectedFlag ? null : date,
      bookmark: false,
      relatedContent: true,
    },
    desktopColumns: 12,
    tabletColumns: 12,
    type: isPaywallProtected(getNestedObject(props, "type")).isPaywallProtectedFlag
      ? null
      : getNestedObject(props, "type"),
    fieldPaywallRoles,
  }
}

export const getproductDetailCardData = (props: any) => {
  const fieldPaywallRoles = getAllRoles(props)
  const imagePaywallData = isPaywallProtected(getNestedObject(props, "image_details"))
  return {
    id: parseInt(props.nid, 10),
    taxonomyLabel: getNestedObject(props, "tags")?.[0],
    heading: isPaywallProtected(getNestedObject(props, "title")).isPaywallProtectedFlag
      ? null
      : getNestedObject(props, "title"),
    description: getNestedObject(props, "description")?.[0]?.value,
    prodInfoTaxonomies: [],
    packInfoTaxonomies: [],
    productListViewImage: {
      src: imagePaywallData?.isPaywallProtectedFlag
        ? getPaywalledImgData(imagePaywallData?.errorObj)?.imageUrl
        : getNestedObject(props, "image_details")?.[0]?.src,
      alt: getNestedObject(props, "image_details")?.[0]?.alt ?? "",
    },
    productCardImage: {
      src: imagePaywallData?.isPaywallProtectedFlag
        ? getPaywalledImgData(imagePaywallData?.errorObj)?.imageUrl
        : getNestedObject(props, "image_details")?.[0]?.src,
      alt: getNestedObject(props, "image_details")?.[0]?.alt ?? "",
      styledimage: imagePaywallData?.isPaywallProtectedFlag
        ? getPaywalledImgData(imagePaywallData?.errorObj)?.imageStyledUrl
        : getNestedObject(props, "image_details")?.[0]?.image_style_uri,
    },
    specifications: isPaywallProtected(getNestedObject(props, "specification"))
      .isPaywallProtectedFlag
      ? null
      : getNestedObject(props, "specification"),
    buttonLabel: getNestedObject(props, "primary_cta")?.[0]?.title,
    isLinkExternal: isExternalLink(getNestedObject(props, "node_url")),
    href: isPaywallProtected(getNestedObject(props, "node_url")).isPaywallProtectedFlag
      ? null
      : getNestedObject(props, "node_url"),
    type: isPaywallProtected(getNestedObject(props, "type")).isPaywallProtectedFlag
      ? null
      : getNestedObject(props, "type"),
    fieldPaywallRoles,
  }
}

export const getNewsStoriesCardData = (props: any) => {
  const fieldPaywallRoles = getAllRoles(props)
  const { lang } = getApiDomainAndLang()

  const date =
    props.event_dates && props.event_dates.length > 0
      ? moment(props.event_dates?.[0]?.values).locale(lang).format(DATE_FORMATS.MMMM_DD_YYYY) ?? ""
      : ""
  const imagePaywallData = isPaywallProtected(getNestedObject(props, "image_details"))
  return {
    imageSrc: imagePaywallData?.isPaywallProtectedFlag
      ? getPaywalledImgData(imagePaywallData?.errorObj)?.imageUrl
      : getNestedObject(props, "image_details")?.[0]?.src,
    newsStoriesContent: {
      id: parseInt(props.nid, 10),
      text: getNestedObject(props, "description")?.[0]?.value,
      heading: isPaywallProtected(getNestedObject(props, "title")).isPaywallProtectedFlag
        ? null
        : getNestedObject(props, "title"),
      buttonLabel: getNestedObject(props, "primary_cta")?.[0]?.title,
      buttonUrl: filterURL(getNestedObject(props, "primary_cta")?.[0]?.uri),
      bookmark: false,
      isButtonUrlExternal: isExternalLink(getNestedObject(props, "primary_cta")?.[0]?.uri),
      taxonomies: {
        taxonomyLabel: getNestedObject(props, "tags"),
      },
      date: isPaywallProtected(props.event_dates).isPaywallProtectedFlag ? null : date,
    },
    type: isPaywallProtected(getNestedObject(props, "type")).isPaywallProtectedFlag
      ? null
      : getNestedObject(props, "type"),
    rank: isPaywallProtected(getNestedObject(props, "rank")).isPaywallProtectedFlag
      ? null
      : getNestedObject(props, "rank"),
    fieldPaywallRoles,
  }
}

export const getEducationCardData = (props: any) => {
  const fieldPaywallRoles = getAllRoles(props)
  const imagePaywallData = isPaywallProtected(getNestedObject(props, "image_details"))
  return {
    educationCardContent: {
      id: parseInt(props.nid, 10),
      taxonomyLabel: getNestedObject(props, "tags")?.[0],
      heading: isPaywallProtected(getNestedObject(props, "title")).isPaywallProtectedFlag
        ? null
        : getNestedObject(props, "title"),
      text: getNestedObject(props, "description")?.[0]?.value,
      time: getNestedObject(props, "duration"),
      contentType: getNestedObject(props, "tags")?.[0],
      buttonLabel: getNestedObject(props, "primary_cta")?.[0]?.title,
      buttonUrl: filterURL(getNestedObject(props, "primary_cta")?.[0]?.uri),
      bookmark: false,
      buttonUrlExternal: isExternalLink(getNestedObject(props, "primary_cta")?.[0]?.uri),
    },
    imageSrc: imagePaywallData?.isPaywallProtectedFlag
      ? getPaywalledImgData(imagePaywallData?.errorObj)?.imageUrl
      : getNestedObject(props, "image_details")?.[0]?.src,
    imgAlt: getNestedObject(props, "image_details")?.[0]?.alt ?? "",
    type: isPaywallProtected(getNestedObject(props, "type")).isPaywallProtectedFlag
      ? null
      : getNestedObject(props, "type"),
    rank: isPaywallProtected(getNestedObject(props, "rank")).isPaywallProtectedFlag
      ? null
      : getNestedObject(props, "rank"),
    fieldPaywallRoles,
  }
}

export const getTherapyCardData = (props: any) => {
  const fieldPaywallRoles = getAllRoles(props)
  const imagePaywallData = isPaywallProtected(getNestedObject(props, "image_details"))
  return {
    id: parseInt(props.nid, 10),
    image: imagePaywallData?.isPaywallProtectedFlag
      ? getPaywalledImgData(imagePaywallData?.errorObj)?.imageUrl
      : getNestedObject(props, "image_details")?.[0],
    therapyCardContent: {
      heading: isPaywallProtected(getNestedObject(props, "title")).isPaywallProtectedFlag
        ? null
        : getNestedObject(props, "title"),
      text: getNestedObject(props, "description")?.[0]?.value,
      buttonUrl: isPaywallProtected(getNestedObject(props, "node_url")).isPaywallProtectedFlag
        ? null
        : getNestedObject(props, "node_url"),
      isButtonUrlExternal: false,
      buttonLabel: isPaywallProtected(getNestedObject(props, "read_more_button_text"))
        .isPaywallProtectedFlag
        ? null
        : getNestedObject(props, "read_more_button_text"),
      taxonomyLabel: getNestedObject(props, "tags")?.[0],
      relatedContent: true,
    },
    bookmarkCallback: false,
    type: isPaywallProtected(getNestedObject(props, "type")).isPaywallProtectedFlag
      ? null
      : getNestedObject(props, "type"),
    rank: isPaywallProtected(getNestedObject(props, "rank")).isPaywallProtectedFlag
      ? null
      : getNestedObject(props, "rank"),
    fieldPaywallRoles,
  }
}

export const getMagazineCardData = (props: any) => {
  const fieldPaywallRoles = getAllRoles(props)
  const imagePaywallData = isPaywallProtected(getNestedObject(props, "image_details"))
  const { lang } = getApiDomainAndLang()
  const formatTaxonomies = (taxonomies: any) => {
    let tags = [] as any[]
    taxonomies?.map((item: string) => {
      const object = {
        taxonomyLabel: item
      }
      tags.push(object)
    })
    return tags
  }
  const magazineTags = isPaywallProtected(props?.topics).isPaywallProtectedFlag
    ? null
    : formatTaxonomies(props?.topics) ?? null

  const createdDate =
    props?.date && props?.date?.length > 0
      ? props?.date[0]?.value
      : ""

  return {
    id: parseInt(props.nid, 10) ?? "",
    magImage: imagePaywallData?.isPaywallProtectedFlag
      ? getPaywalledImgData(imagePaywallData?.errorObj)
      : getNestedObject(props, "image_details")?.[0] ?? null,
    taxonomies: magazineTags ?? [],
    title: isPaywallProtected(getNestedObject(props, "title")).isPaywallProtectedFlag
      ? null
      : getNestedObject(props, "title") ?? null,
    desc: isPaywallProtected(getNestedObject(props, "description")).isPaywallProtectedFlag
      ? null
      : getNestedObject(props, "description")?.[0]?.value ?? null,
    createdDate: isPaywallProtected(props?.date)?.isPaywallProtectedFlag ? null : createdDate,
    time: getNestedObject(props, "reading_time") ?? null,
    nodeUrl: getNestedObject(props, "node_url") ?? null,
    row: NUMBERS.THREE,
    type: isPaywallProtected(getNestedObject(props, "type")).isPaywallProtectedFlag
      ? null
      : getNestedObject(props, "type"),
    rank: isPaywallProtected(getNestedObject(props, "rank")).isPaywallProtectedFlag
      ? null
      : getNestedObject(props, "rank") ?? null,
    fieldPaywallRoles,
  }
}

export const getRelatedContentData = (props: any) =>
  props
    ?.map((content: any) => {
      // eslint-disable-next-line default-case
      switch (content?.type) {
        case ERelatedContent.EDUCATION: {
          return getEducationCardData(content)
        }
        case ERelatedContent.EVENT: {
          return getEventCardData(content)
        }
        case ERelatedContent.THERAPY: {
          return getTherapyCardData(content)
        }
        case ERelatedContent.NEWS: {
          return getNewsStoriesCardData(content)
        }

        case ERelatedContent.PRODUCT: {
          return getProductCardData(content)
        }
        case ERelatedContent.CSV_PRODUCT: {
          return getproductDetailCardData(content)
        }
        case ERelatedContent.MAGAZINE: {
          return getMagazineCardData(content)
        }
      }
    })
    .sort((a: any, b: any) => a.rank - b.rank)
